<template>
  <div class="body_bgi full bgs_full">
    <big-screen-header :headerName="'巴林右旗' + `${townName}` + '防疫统计'" @selectJob="selectJob" />

    <div class="content_box w_full h_full-50 flex_row_around p_10 border_box">
      <!-- 1 -->
      <div class="w_32_5_p h_full flex_column_between">
        <!-- <mian-yi-yi-miao-chart
          :areaCode="townId" 
          :level="level"
          countyId="150423000000"
          :townId="townId"
          :jobId="jobId"
          @changeCount="changeCount"
        /> -->

        <cun-lan-mian-yi
          :areaCode="townId" 
          parentAreaCode="150423000000"
          :parentLevel="level"
          :jobId="jobId"
          @changeCount="changeCount"
        />
      </div>

      <!-- 2 -->
      <div class="w_32_5_p h_full flex_column_between">
        <div class="w_full h_49_p bgs_full chart_box_bgi_1">
          <!-- <da-ban-map v-if="townName == '大板镇'" />
          <su-bo-ri-ga v-if="townName == '索博日嘎镇'" />
          <xing-fu-zhi-lu v-if="townName == '幸福之路苏木'" />
          <ba-yan-hu-shuo v-if="townName == '巴彦琥硕镇'" />
          <cha-gan-mu-lun v-if="townName == '查干沐沦苏木'" />
          <mei-dian-hua v-if="townName == '大板煤电化基地'" />
          <ba-yan-ta-la v-if="townName == '巴彦塔拉苏木'" />
          <cha-gan-nuo-er v-if="townName == '查干诺尔镇'" />
          <bao-ri-wu-su v-if="townName == '宝日勿苏镇'" />
          <xi-la-mu-lun v-if="townName == '西拉沐沦苏木'" /> -->

          <component :is="componentName"></component>
        </div>

        <!-- 文字描述 -->
        <div class="w_full h_49_p bgs_full chart_box_bgi_1 p_5 color_fff text_1">
          <div class="w_full flex_row_between p_10">
            <div class="text_1 jian_bian_text_1 fw_bold"> 
              {{ townName }}存栏数：<span class="font_number theme_color_orange ls_2">
                <animate-number from="0" :to="animalCount" :key="animalCount" duration="3000" />
              </span> 
            </div>

            <div class="text_1 jian_bian_text_1 fw_bold"> 
              {{ townName }}免疫数：<span class="font_number theme_color_green ls_2">
                <animate-number from="0" :to="fangYiCount" :key="fangYiCount" duration="3000" />
              </span> 
            </div>
          </div>
          
          <!-- xxx 镇描述 -->
          <div class="w_full h_full-70 text_indent_2 ph_10 overflow_y_auto scroll_box">{{ content }}</div>
        </div>
      </div>

      <!-- 3 -->
      <div class="w_32_5_p h_full flex_column_between">
        <div class="full bgs_full chart_box_bgi_2 p_10 border_box">
          <div class="w_full h_5_p text_center">
            <span class="jian_bian_text_1 fw_bold text_1">嘎查村列表</span>
          </div>

          <div class="scroll_box w_full h_95_p overflow_y_auto">
            <div class="mb_5 font_number w_full h_30 lh_30 text_1 color_fff" v-for="(item, index) in villageList"
              :key="index">
              <div class="w_full text_center cursor hover_main_color" @click="goToVillageDetail(index)">
                {{ item.name || '' }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import defMixin from '@/mixins/def';
import componentsMixin from '@/mixins/components.js'
import geoJsonMapMixin from '@/mixins/geoJson-map.js'
import dataChartMixin from '@/mixins/data-charts.js'

import { getVillageList, getTownInfo } from '@/api/base/base.js'

export default {
  name: 'TownPage',
  mixins: [defMixin, componentsMixin, geoJsonMapMixin, dataChartMixin],
  components: { },
  data() {
    return {
      animalCount: null,
      fangYiCount: null,

      townId: '',
      level: '',
      townName: '',
      content: '',

      jobId: null,

      villageList: [],

      data2: {},
      data3: {},
    };
  },
  computed: {
    componentName() {
      let component = ''

      if (this.townName == '索博日嘎镇') {
        component = 'SuBoRiGa'
      } else if (this.townName == '查干沐沦苏木') {
        component = 'ChaGanMuLun'
      } else if (this.townName == '巴彦琥硕镇') {
        component = 'BaYanHuShuo'
      } else if (this.townName == '幸福之路苏木') {
        component = 'XingFuZhiLu'
      } else if (this.townName == '大板镇') {
        component = 'DaBan'
      } else if (this.townName == '大板煤电化基地') {
        component = 'MeiDianHua'
      } else if (this.townName == '巴彦塔拉苏木') {
        component = 'BaYanTaLa'
      } else if (this.townName == '查干诺尔镇') {
        component = 'ChaGanNuoEr'
      } else if (this.townName == '宝日勿苏镇') {
        component = 'BaoRiWuSu'
      } else if (this.townName == '西拉沐沦苏木') {
        component = 'XiLaMuLun'
      }

      return component
    }
  },
  mounted() {
    let townId = this.$route.params.id;
    let level = this.$route.params.level;
    let name = this.$route.params.name;

    this.townId = townId;
    this.level = level;
    this.townName = name;

    // 获取 xxx 镇的 说明文字
    this.getTownInfo(townId)

    // 获取 巴林右旗 -大坂镇 - 所属嘎查
    this.getVillageList(townId);
  },
  methods: {
    selectJob(jobId) {
      this.jobId = jobId;
    },

    // 监听 地区的存栏防疫 总数
    changeCount(obj) {
      this.animalCount = obj.animalCount
      this.fangYiCount = obj.fangYiCount
    },

    // 获取 xxx 镇的 说明文字
    async getTownInfo(id) {
      let res = await getTownInfo(id)
      try {
        if (res.code == 1000) {
          this.content = res.data.content
        }
      } catch(err) {
        console.log(err)
      }
    },

    // 获取 乡镇下的 所有 嘎查（村）
    async getVillageList(townId) {
      let res = await getVillageList(townId);
      try {
        if (res.code == 1000) {
          this.villageList = res.data;
        }
      }
      catch (err) {
        console.log(err)
      }
    },

    // 跳转到 嘎查详情页
    goToVillageDetail(index) {
      let villageId = this.villageList[index].id;
      let name = this.villageList[index].name;
      // let isGroup = this.villageList[index].isGroup;
      let isGroup = false;
      
      this.$router.push({
        path: `/villageFyPage/${this.townId}/${this.townName}/4/${villageId}/${name}/${isGroup}`
      });
    }




  }
}
</script>